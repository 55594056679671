import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import get from 'lodash/get';

import {
  FilteringState,
  IntegratedPaging,
  PagingState,
  SortingState,
} from '@devexpress/dx-react-grid';

import { closeDialog } from '../../store/dialogs';
import WebAPIClient from '../../api';
import BaseTable from '../table/tables/BaseTable';
import BaseDialog from './BaseDialog';
import ComponentLoader from '../Loaders/ComponentLoader';

function EmailedUsersDialog() {
  const dispatch = useDispatch();

  const { alarm } = useSelector((state) => state.dialogs.emailedUsers);
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [columns] = useState([
    { title: 'Name', name: 'name', width: 0.65 },
    { title: 'Email', name: 'email', width: 0.65 },
  ]);

  const fetchUsers = useCallback(async () => {
    if (alarm?.alarm_id) {
      setLoading(true);
      const result = await new WebAPIClient().GET(
        `/resource/emailed_users/${alarm.org_id}/${alarm.alarm_id}`
      );
      setUsers(result);
      setLoading(false);
    }
  }, [alarm]);

  useEffect(() => {
    fetchUsers();
  }, [fetchUsers]);

  const handleClose = () => {
    dispatch(closeDialog('emailedUsers'));
  };

  return (
    <BaseDialog
      id={get(alarm, 'alarm_id', '')}
      title='Emailed Users'
      handleClose={handleClose}>
      {loading ? (
        <div style={{ height: '50px' }}>
          <ComponentLoader height='35px' width='35px' />
        </div>
      ) : (
        <BaseTable rows={users} columns={columns}>
          <FilteringState />
          <SortingState
            defaultSorting={[{ columnName: 'name', direction: 'asc' }]}
          />
          <PagingState defaultCurrentPage={0} pageSize={10} />
          <IntegratedPaging />
        </BaseTable>
      )}
    </BaseDialog>
  );
}

export default EmailedUsersDialog;
